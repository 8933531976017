<template>
  <div class="ceremony">
    <el-row :gutter="50">
      <el-col :span="24" :md="7">
        <el-carousel arrow="always" height="250px" indicator-position="none">
          <el-carousel-item
            v-for="(item, index) in ceremonyData.images"
            :key="index"
          >
            <div class="img-box"><img v-lazy="item" alt="" /></div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="24" :md="16">
        <div class="ceremony-content">
          <p class="txt" v-html="$t('Ceremony.' + ceremonyData.desc)"></p>
        </div>
        <div class="btn-action" v-if="ceremonyData.btnList.length > 0">
          <a
            v-for="(btn, index) in ceremonyData.btnList"
            :key="index"
            :href="btn.link"
            :download="btn.fileName ? btn.fileName : ''"
          >
            <button class="ceremony-btn">
              {{ $t("Ceremony." + btn.text) }}
            </button>
          </a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Ceremony",
  props: {
    year: {
      type: String,
      default: "2023-2025",
    },
  },
  data() {
    return {
      //   swiperList: [],
      ceremonyData: {},
      ceremonyList: [
        {
          year: "2021-2023",
          desc: "title",
          images: [
            require("../../assets/images/ceremony/Judging Panel.webp"),
            require("../../assets/images/ceremony/Photo 1.webp"),
            require("../../assets/images/ceremony/Photo 3.webp"),
            require("../../assets/images/ceremony/Photo 4.webp"),
            require("../../assets/images/ceremony/Photo 4(1).webp"),
            require("../../assets/images/ceremony/Photo 5.webp"),
            require("../../assets/images/ceremony/Photo 6.webp"),
            require("../../assets/images/ceremony/photo 7.webp"),
            require("../../assets/images/ceremony/Photo 8.webp"),
            require("../../assets/images/ceremony/photo 9.webp"),
            require("../../assets/images/ceremony/Photo 10.webp"),
            require("../../assets/images/ceremony/Social Posting.webp"),
          ],
          btnList: [
            {
              text: "btn",
              link: "/pdf/Job 2338 SportHour Brochure 02.pdf",
              fileName: "Job 2338 SportHour Brochure 02.pdf",
            },
            {
              text: "btn_watch",
              link: "https://youtu.be/bGzqwUnd_Gk",
              fileName: "",
            },
          ],
        },
        {
          year: "2023-2025",
          desc: "title_23_25",
          images: [
            require("../../assets/images/ceremony/23-25/photo-1.webp"),
            require("../../assets/images/ceremony/23-25/photo-2.webp"),
            require("../../assets/images/ceremony/23-25/photo-3.webp"),
            require("../../assets/images/ceremony/23-25/photo-4.webp"),
            require("../../assets/images/ceremony/23-25/photo-5.webp"),
            require("../../assets/images/ceremony/23-25/photo-6.webp"),
            require("../../assets/images/ceremony/23-25/photo-7.webp"),
            require("../../assets/images/ceremony/23-25/photo-8.webp"),
            require("../../assets/images/ceremony/23-25/photo-9.webp"),
            require("../../assets/images/ceremony/23-25/photo-10.webp"),
            require("../../assets/images/ceremony/23-25/photo-11.webp"),
            require("../../assets/images/ceremony/23-25/photo-12.webp"),
            require("../../assets/images/ceremony/23-25/photo-13.webp"),
            require("../../assets/images/ceremony/23-25/photo-14.webp"),
          ],
          btnList: [
            {
              text: "download_brochure",
              link: "/pdf/SHC Ceremony 2023 Pamphlet.pdf",
              fileName: "SHC Ceremony 2023 Pamphlet.pdf",
            },
            {
              text: "btn_watch",
              link: "https://www.youtube.com/watch?v=gapoS9iZXZw",
            },
          ],
        },
      ],
    };
  },
  created() {
    // const requireContext = require.context(
    //   "@/assets/images/ceremony",
    //   false,
    //   /.webp$/
    // );
    // this.swiperList = requireContext.keys().map((item) => ({
    //   imgUrl: require("@/assets/images/ceremony" + item.slice(1)),
    // }));
    // console.log(this.swiperList);
    this.getCeremony();
  },
  watch: {
    year: {
      handler: "getCeremony",
      immediate: true,
    },
  },
  methods: {
    getCeremony() {
      this.ceremonyData = this.ceremonyList.find(
        (item) => item.year === this.year
      );
    },
  },
};
</script>

<style scoped lang="scss">
.ceremony {
  display: flex;
  margin: 30px 50px;
  letter-spacing: 1px;
  justify-content: space-between;

  .img-box {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    text-align: left;
  }

  &-btn {
    margin-top: 10px;
    cursor: pointer;
    padding: 15px 10px;
    width: 250px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #1900ff;
    border: 1px solid #1900ff;
    color: #fff;
    transition: 0.3s;
    margin-right: 10px;
  }

  &-btn-watch {
    margin-top: 10px;
    cursor: pointer;
    padding: 15px 10px;
    width: 250px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #1900ff;
    border: 1px solid #1900ff;
    color: #fff;
    transition: 0.3s;
    margin-left: 10px;
  }
}
</style>
