<template>
  <div class="Seminar">
    <p class="Seminar-title">{{ $t('Seminar.subTitle') }}</p>
    <div>
      <p class="Seminar-icon"></p>
      <p class="title">{{ $t('Seminar.subTitle1') }}</p>
      <p class="content">{{ $t('Seminar.content') }}</p>
    </div>
    <div class="caption">
      <div class="subcaption" v-for="item in caption" :key="item.cont_id">
        <div class="caption-right">
          <p class="caption-id">{{ item.cont_id }}</p>
          <p class="caption-txt">{{ $t(`Seminar.captions.${item.txt}`) }}</p>
        </div>
        <div class="caption_pic">
          <img v-lazy="item.pic" alt="" :key="item.pic" />
        </div>
      </div>
    </div>
    <div class="section">
      <p class="Seminar-icon"></p>
      <p class="title">{{ $t('Seminar.subTitle2') }}</p>
      <p class="content">{{ $t('Seminar.content2') }}</p>
      <img src="../../assets/images/banner(2).webp" alt="" />
    </div>
    <div class="banner2-pic">
      <div class="content2">
        <div class="cont2-pic" v-for="(item, index) in bannerList" :key="item.pic" :style="{ height: item.conHeight }">
          <div :class="[(index + 1) % 2 !== 0 ? 'triangle-bottomleft' : 'triangle-bottomright']"></div>
          <img class="img1" v-lazy="item.pic" alt="" :style="{ height: item.height }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Seminar21To23',
  data() {
    return {
      caption: [
        {
          cont_id: '01',
          txt: 'content_one1',
          pic: require('@/assets/images/banner1-(1).png')
        },
        {
          cont_id: '02',
          txt: 'content_one2',
          pic: require('@/assets/images/banner1-(2).png')
        },
        {
          cont_id: '03',
          txt: 'content_one3',
          pic: require('@/assets/images/banner1-(3).png')
        },
        {
          cont_id: '04',
          txt: 'content_one4',
          pic: require('@/assets/images/banner1-(4).png')
        }
      ],
      bannerList: [
        {
          pic: require('@/assets/images/seminar/Seminar-banner1.png'),
          height: '400px',
          conHeight: '450px'
        },
        {
          pic: require('@/assets/images/seminar/Seminar-banner2.png'),
          height: '400px',
          conHeight: '500px'
        },
        {
          pic: require('@/assets/images/seminar/Seminar-banner3.png'),
          height: '400px',
          conHeight: '450px'
        },
        {
          pic: require('@/assets/images/seminar/Seminar-banner4.png'),
          height: '400px',
          conHeight: '500px'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Seminar {
  width: 80%;
  margin: 0 auto;

  &-title {
    margin: 50px;
    font-size: 17px;
    line-height: 30px;
  }

  &-icon {
    width: 40px;
    height: 40px;
    margin: 40px auto;
    background: url('../../assets/images/Group 11846.svg') 50%/50% no-repeat;
  }

  .title {
    font-size: 24px;
    color: #1e30f6;
    font-family: PingFang-Heavy;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
  }

  .caption {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;

    .subcaption {
      height: 706px;
      width: 35%;
      border-radius: 20px;
      border: 4px solid rgba(187, 212, 96, 0.698);
      margin-top: 70px;
      border-left: none;
      border-right: none;

      .caption_pic {
        width: 100%;
        height: 320px;
        background: #000;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .caption-right {
        width: 100%;
        position: relative;

        .caption-txt {
          margin-bottom: 50px;
          text-align: left;
          line-height: 30px;
          height: 210px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }

  .section {
    img {
      width: 100%;
      margin-top: 50px;
    }
  }

  .banner2-pic {
    width: 70%;
    margin-left: 10%;
    margin-top: 90px;

    .content2 {
      .cont2-pic {
        background: rgba(187, 212, 96, 0.698);
        position: relative;

        img {
          display: block;
          transition: all 0.7s;
          border-radius: 10px;
          margin-left: 10%;
          width: 95%;
        }

        .triangle-bottomleft {
          position: absolute;
          width: 7%;
          height: 100%;
          background: rgb(251, 251, 251);
          clip-path: polygon(0 0, 100% 100%, 0 100%);
        }

        .triangle-bottomright {
          position: absolute;
          width: 7%;
          height: 100%;
          background: rgb(255, 255, 255);
          clip-path: polygon(0 0, 0 100%, 100% 0);
          top: 0;
          left: 0;
        }
      }

      img:hover {
        transform: scale(1.1, 1.1);

        /*对前效果的过度*/
        // opacity: 1;
        z-index: 1;
      }
    }
  }
}

.caption-id {
  font-size: 65px;
  font-style: italic;
  color: #6ec9f5;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .caption {
    flex-direction: column;

    .subcaption {
      width: 100% !important;
      height: auto !important;
      padding-bottom: 30px;

      .caption-txt {
        height: auto !important;
        margin-bottom: 20px;
      }
    }
  }
  .content2 {
    width: 100% !important;

    .cont2-pic {
      height: 170px !important;

      img {
        height: 140px !important;
      }
    }
  }
}
</style>
