<template>
  <div>

    <div id="cover">
      <img src="../../../public/photo1.jpg" />
      <img src="../../../public/photo2.jpg" />
      <img src="../../../public/photo3.jpg" />
      <img src="../../../public/photo4.jpg" />
    </div>
    <div class="desc">
      <h4>{{ $t('kick_off.desc') }}</h4>
    </div>

  </div>
</template>

<script>
export default {
  name: "Kickoff"
}
</script>

<style scoped lang="scss">

* {
  font-family: "PingFang-Medium", sans-serif;
}

#cover {
  width: 87%;
  height: auto;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

#cover img {
  flex: 0 0 50%;
  padding: 10px;
  object-fit: cover;
  width: 50%;
  
}

.desc {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 14rem;
    
  
  h4 {
    line-height: 1.5;
    text-align: center;
    
  }
}

@media screen and (max-width: 990px) {
  .desc {
    margin-left: 6rem; 
    font-size: 16px;
  }
}

</style>