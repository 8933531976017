<template>
  <div class="seminar">
    <div class="desc">
      <p class="txt" v-html="$t('Seminar.text_23_25')"></p>
    </div>
    <div class="sport-session">
      <p class="Seminar-icon"></p>
      <p class="title">{{ $t('Seminar.title_23_25') }}</p>
      <p class="subtitle">{{ $t('Seminar.subtitle_sport') }}</p>
      <div class="images">
        <img v-for="(item, index) in sportImages" :key="index" v-lazy="item.img" alt="" />
      </div>
    </div>
    <div class="leadership-session">
      <p class="subtitle">{{ $t('Seminar.subtitle_leadership') }}</p>
      <div class="items-session">
        <div class="item" v-for="item in caption" :key="item.cont_id">
          <div class="caption-right">
            <p class="caption-id">{{ item.cont_id }}</p>
          </div>
          <div class="caption_pic">
            <img v-lazy="item.img" alt="" :key="item.cont_id" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="seminar-button">
      <a href="https://alt.jotfor.ms/ihksports/-shc-sharing">
        <button class="button-sign-up">
          {{ $t('Seminar.signup') }}
        </button>
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Seminar',
  data() {
    return {
      sportImages: [
        {
          img: require('@/assets/images/seminar/sport_image_1.webp')
        },
        {
          img: require('@/assets/images/seminar/sport_image_2.webp')
        }
      ],
      caption: [
        {
          cont_id: '01',
          img: require('@/assets/images/seminar/Thought Leadership Series-01.webp')
        },
        {
          cont_id: '02',
          img: require('@/assets/images/seminar/Thought Leadership Series-02.webp')
        },
        {
          cont_id: '03',
          img: require('@/assets/images/seminar/Thought Leadership Series-03.webp')
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
* {
  font-family: 'PingFang-Medium', sans-serif;
}

.seminar {
  margin: 50px 140px;

  .desc {
    p {
      font-size: 17px;
      line-height: 30px;
    }
  }

  .sport-session,
  .leadership-session {
    margin-bottom: 64px;
    .Seminar {
      width: 80%;
      margin: 0 auto;

      &-title {
        margin: 50px;
        font-size: 17px;
        line-height: 30px;
      }

      &-icon {
        width: 40px;
        height: 40px;
        margin: 40px auto;
        background: url('../../assets/images/Group 11846.svg') 50%/50% no-repeat;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 18px;
      font-weight: bold;
    }

    .images {
      display: flex;
      justify-content: space-evenly;
      img {
        max-width: 500px;
        width: 100%;
        height: 350px;
      }
    }
  }

  .leadership-session {
    .items-session {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      .item {
        width: 35%;
        border-radius: 20px;
        border-top: 4px solid rgba(187, 212, 96, 0.698);
        margin-top: 48px;
        border-left: none;
        border-right: none;

        .caption_pic {
          width: 100%;
          border-radius: 40px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }

        .caption-id {
          font-size: 65px;
          font-style: italic;
          color: #6ec9f5;
          margin: 0;
        }
      }
    }
  }
}

.seminar-poster {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.button-sign-up {
  cursor: pointer;
  padding: 35px 30px;
  width: 450px;
  border-radius: 4px;
  font-size: 22px;
  background-color: #1900ff;
  border: 1px solid #1900ff;
  color: #fff;
  transition: 0.3s;
  margin-bottom: 3rem;
}

.button-sign-up:hover {
  background-color: white;
  border: 1px solid #1900ff;
  color: #1900ff;
}
</style>
