<template>
  <div class="ceremony">
    <el-row :gutter="50">
      <el-col :span="24" :md="7">
        <el-carousel arrow="always" height="250px" indicator-position="none">
          <el-carousel-item v-for="item in swiperList" :key="swiperList.imgUrl">
            <div class="img-box"><img v-lazy="item.imgUrl" alt=""></div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="24" :md="16">
        <div class="ceremony-content"><p>{{ $t('Ceremony.title') }}</p></div>
        <div>
          <a href="/pdf/Job 2338 SportHour Brochure 02.pdf" download="Job 2338 SportHour Brochure 02.pdf">
            <button class="ceremony-btn">
              {{ $t('Ceremony.btn') }}
            </button>
          </a>
          <a href="https://youtu.be/bGzqwUnd_Gk">
            <button class="ceremony-btn-watch">
              {{ $t('Ceremony.btn_watch') }}
            </button>
          </a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Ceremony21To23",
  data() {
    return {
      swiperList: [],
    }
  },
  created() {
    const requireContext = require.context('@/assets/images/ceremony', false, /.webp$/)
    this.swiperList = requireContext.keys().map(item => ({
      imgUrl: require('@/assets/images/ceremony' + item.slice(1))
    }))
  }
}
</script>

<style scoped lang="scss">
.ceremony {
  display: flex;
  margin: 30px 50px;
  letter-spacing: 1px;
  justify-content: space-between;

  .img-box {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    text-align: left;
  }

  &-btn {
    margin-top: 10px;
    cursor: pointer;
    padding: 15px 10px;
    width: 250px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #1900ff;
    border: 1px solid #1900ff;
    color: #fff;
    transition: 0.3s;
    margin-right: 10px;
  }

  &-btn-watch {
    margin-top: 10px;
    cursor: pointer;
    padding: 15px 10px;
    width: 250px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #1900ff;
    border: 1px solid #1900ff;
    color: #fff;
    transition: 0.3s;
    margin-left: 10px;
  }
}

</style>