<template>
  <div class="ceremony">
    <div class="ceremony-button">
      <el-radio-group
        v-model="activeName"
        fill="#00C2FC"
        @change="handleChange"
        size="small "
      >
        <el-radio-button label="2023-2025"></el-radio-button>
        <el-radio-button
          label="2021-2023"
          v-if="tempComponent !== 'Kickoff'"
        ></el-radio-button>
      </el-radio-group>
    </div>

    <component :is="tempComponent" :year="computedYear"></component>
  </div>
</template>

<script>
import Ceremony21To23 from "./Ceremony21To23";
import Ceremony from "./Ceremony";
import Seminar from "./Seminar";
import Seminar21To23 from "./Seminar21To23";
import Kickoff from "./Kickoff";
export default {
  name: "index",
  components: {
    Ceremony21To23,
    Ceremony,
    Seminar,
    Seminar21To23,
    Kickoff,
  },
  data() {
    return {
      activeName: "2023-2025",
      tempComponent: "Ceremony",
    };
  },
  computed: {
    computedYear() {
      return this.activeName;
    },
  },
  methods: {
    handleChange(val) {
      const routePath = this.$route.path;
      if (routePath === "/ceremony") {
        // this.tempComponent = val === '2021-2023' ? 'Ceremony21To23':'Ceremony'
        this.tempComponent = Ceremony;
        this.activeName = val;
      }
      if (routePath === "/seminar") {
        this.tempComponent = val === "2021-2023" ? "Seminar21To23" : "Seminar";
      }
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.path === "/ceremony") {
          this.tempComponent = "Ceremony";
          // this.activeName = "2021-2023";
        }
        if (to.path === "/seminar") {
          this.tempComponent = "Seminar21To23";
          this.activeName = "2021-2023";
        }
        if (to.path === "/kickoff") {
          this.tempComponent = "Kickoff";
          this.activeName = "2023-2025";
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.ceremony {
  &-button {
    margin-top: 30px;
    text-align: left;
    margin-left: 50px;
  }
}
</style>
